


















import Vue from 'vue'
import { LMap, LTileLayer, LControlZoom } from 'vue2-leaflet'
import { LatLngBounds, Map } from 'leaflet'
import MapMarker from './MapMarker.vue'

export default Vue.extend({
  props: {
    fixedlyPositionedElementsPaneAdditionalClassesNames: [Array, String],
    zoomControlButtonsAdditionalClassesNames: {
      type: [Array, String],
      default: 'zoom-control-buttons'
    },
    requestedBounds: LatLngBounds,
    places: Array
  },
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    MapMarker
  },
  computed: {
    map () : Map {
      return (  this.$refs.map && (this.$refs.map as LMap).mapObject)!
    }
  },
  mounted () {
    this.$slots.default && this.$nextTick(() => {
      const fixedlyPositionedElementsPane = this.map.createPane('fixedly-positioned-elements')
      this.fixedlyPositionedElementsPaneAdditionalClassesNames
        && this.addClassNameOrNamesToElement(fixedlyPositionedElementsPane, this.fixedlyPositionedElementsPaneAdditionalClassesNames as string[] | string)
      fixedlyPositionedElementsPane.append(...this.$slots.default!.map(slotElement => slotElement.elm as HTMLElement))
      new MutationObserver(mutations => {
        mutations.forEach(mutationRecord => {
          const transformPropertyValue = (mutationRecord.target as HTMLElement).style.transform
          fixedlyPositionedElementsPane.style.transform = `translate3d(${transformPropertyValue.slice(transformPropertyValue.indexOf('(') + 1, -1).split(', ').map(parameter => -parseFloat(parameter) + 'px').join(', ')})`
        })
      }).observe(this.map.getPanes().mapPane, { attributes: true, attributeFilter: ['style'] })
    })
    this.$nextTick(() => {
      const zoomControlButtons = (this.$refs.zoomControlButtons as LControlZoom).mapObject.getContainer()!
      this.zoomControlButtonsAdditionalClassesNames
        && this.addClassNameOrNamesToElement(zoomControlButtons, this.zoomControlButtonsAdditionalClassesNames as string[] | string)
      zoomControlButtons.childNodes.forEach(button => {
        button.addEventListener('mousedown', this.emitZoomControlButtonPressedOrTouchedEvent)
        button.addEventListener('touchstart', this.emitZoomControlButtonPressedOrTouchedEvent)
      })
    })
    const currentBounds = this.map.getBounds()
    currentBounds.equals(this.requestedBounds) ? this.$emit('bounds-applied-and-stayed-unchanged') : this.$emit('bounds-applied-and-changed', currentBounds)
  },
  beforeDestroy () {
    (this.$refs.zoomControlButtons as LControlZoom).mapObject.getContainer()!.childNodes.forEach(button => {
      button.removeEventListener('mousedown', this.emitZoomControlButtonPressedOrTouchedEvent)
      button.removeEventListener('touchstart', this.emitZoomControlButtonPressedOrTouchedEvent)
    })
  },
  methods: {
    addClassNameOrNamesToElement (element: HTMLElement, classNameOrNames: string | string[]) {
      Array.isArray(classNameOrNames)
        ? element.classList.add(...classNameOrNames)
        : element.classList.add(classNameOrNames)
    },
    emitBoundsRelatedEventIfNecessary (bounds: LatLngBounds) {
      if (this.requestedBounds) {
        bounds.equals(this.requestedBounds) ? this.$emit('bounds-applied-and-stayed-unchanged') : this.$emit('bounds-applied-and-changed', bounds)
      } else {
        this.$emit('bounds-changed', bounds)
      }
    },
    emitZoomControlButtonPressedOrTouchedEvent () {
      this.$emit('zoom-control-button-pressed-or-touched')
    }
  }
})
