













import Vue from 'vue'
import { ToggleButton } from 'vue-js-toggle-button'

export default Vue.extend({
  components: {
    ToggleButton
  },
  props: {
    value: Boolean,
    label: String
  }
})
