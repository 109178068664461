




















import Vue from 'vue'

export default Vue.extend({
  props: {
    doNotDisplayCloseButton: Boolean,
    title: String,
    content: String
  }
})
