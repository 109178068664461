






import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendar, faClock, faClone, faHeart } from '@fortawesome/free-regular-svg-icons'
import { faHome, faExclamationTriangle, faTimes, faLocationArrow, faPhone, faAt, faLaptop, faQuestionCircle, faCheckCircle, faAngleDoubleLeft, faSearch, faCog, faCalendarDay, faCalendarCheck, faHeart as faSolidHeart, faUtensils} from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faHome, faExclamationTriangle, faTimes, faCalendar, faClock, faLocationArrow, faPhone, faAt, faLaptop, faQuestionCircle, faCheckCircle, faClone, faAngleDoubleLeft, faSearch, faCog, faCalendarDay, faCalendarCheck, faHeart, faSolidHeart, faUtensils)

const availableSizes = Object.freeze({
  0.75: 'xs',
  0.875: 'sm',
  1.33: 'lg',
  2: '2x',
  3: '3x',
  4: '4x',
  5: '5x',
  6: '6x',
  7: '7x',
  8: '8x',
  9: '9x',
  10: '10x'
})

export default Vue.extend({
  components: {
    FontAwesomeIcon
  },
  props: {
    name: {
      type: String,
      required: true
    },
    size: Number,
    solid: Boolean
  },
  data () {
    return {
      availableSizes
    }
  }
})
