














































import Vue from 'vue'
import InformationBox from './InformationBox.vue'

const MINIMUM_NUMER_OF_PLACES_TO_DISPLAY_ICON_WITH_NUMBER = 5
const FETCH_NUMBER_OF_PLACES_REQUEST_URL_BASIS = `${location.origin}/en/watchout/api/places/count`
const FETCH_AVAILABLE_LANGUAGES_REQUEST_URL_BASIS = `${location.origin}/en/watchout/api/languages`
const WARNING_INFORMATION_BOX_CLOSURE_STATUS_LOCAL_STORAGE_KEY = 'warningInformationBoxClosed'

export default Vue.extend({
  components: {
    InformationBox,
  },
  data () {
    return {
      numberOfPlaces: undefined as undefined | number,
      warningInformationBoxClosed: localStorage?.getItem(WARNING_INFORMATION_BOX_CLOSURE_STATUS_LOCAL_STORAGE_KEY) === 'true',
      minimumNumerOfPlacesToDisplayIconWithNumber: MINIMUM_NUMER_OF_PLACES_TO_DISPLAY_ICON_WITH_NUMBER,
      availableLanguages: [],
    }
  },
  created () {
    fetch(FETCH_NUMBER_OF_PLACES_REQUEST_URL_BASIS).then(response => response.json())
      .then(data => this.numberOfPlaces = data['place_count']);
    fetch(FETCH_AVAILABLE_LANGUAGES_REQUEST_URL_BASIS).then(response => response.json())
      .then(data => {this.availableLanguages = data;});
  },
  watch: {
    warningInformationBoxClosed () {
      localStorage?.setItem(WARNING_INFORMATION_BOX_CLOSURE_STATUS_LOCAL_STORAGE_KEY, 'true')
    }
  }
})
