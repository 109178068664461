




















import Vue from 'vue'
import ComboBox from './ComboBox.vue'

// const REQUEST_URL_BASIS = 'https://nominatim.openstreetmap.org/search?format=jsonv2'
const REQUEST_URL_BASIS = 'https://photon.komoot.io/api/'
const SEARCH_PHRASE_PARAMETER_KEY = 'q'
// const DEFAULT_SEARCH_PHRASE = 'Kraków'
const LOCATION_BIAS_LONGITUDE_PARAMETER_KEY = 'lon'
const DEFAULT_LOCATION_BIAS_LONGITUDE = 19.997153435836697
const LOCATION_BIAS_LATITUDE_PARAMETER_KEY = 'lat'
const DEFAULT_LOCATION_BIAS_LATITUDE = 50.0469432
const REQUEST_TIMEOUT_TIMEOUT = 400

export default Vue.extend({
  props: {
    value: Object
  },
  components: {
    ComboBox
  },
  data () {
    return {
      locations: [],
      requestURL: new URL(REQUEST_URL_BASIS),
      requestTimeoutIdentifier: undefined as undefined | number,
      multiselectComponentData: undefined as any,
      searchRequested: undefined as undefined | boolean
    }
  },
  computed: {
    selectedItemLabel () : string {
      return this.label(this.value)
    },
    searchPhrase: {
      get () : string {
        return this.multiselectComponentData && this.multiselectComponentData.search
      },
      set (phrase) {
        this.multiselectComponentData.search = phrase
      }
    }
  },
  mounted () {
    this.multiselectComponentData = (this.$refs.comboBox as any).$refs.multiselect.$data
    // this.searchPhrase = DEFAULT_SEARCH_PHRASE
  },
  watch: {
    locations () {
      this.$emit('input', this.locations[0])
    }
  },
  methods: {
    label (option: any) {
      return option && option['properties'] && Array.from(new Set([
        option['properties']['name'],
        option['properties']['housenumber'],
        option['properties']['street'],
        option['properties']['city'],
        // option['properties']['state'],
        option['properties']['country']
      ])).filter(property => property).join(', ')
      // return option && option['display_name']
    },
    search (phrase: string) {
      if (phrase) {
        this.searchRequested = true
        clearTimeout(this.requestTimeoutIdentifier)
        this.requestTimeoutIdentifier = setTimeout(() => {
          this.requestURL.searchParams.set(SEARCH_PHRASE_PARAMETER_KEY, phrase)
          this.requestURL.searchParams.set(LOCATION_BIAS_LONGITUDE_PARAMETER_KEY, this.value?.geometry.coordinates[0] || DEFAULT_LOCATION_BIAS_LONGITUDE)
          this.requestURL.searchParams.set(LOCATION_BIAS_LATITUDE_PARAMETER_KEY, this.value?.geometry.coordinates[1] || DEFAULT_LOCATION_BIAS_LATITUDE)
          fetch(this.requestURL.toString()).then(response => response.json())
            .then(data => this.locations = data['features'].length ? data['features'] : this.value ? [this.value] : data['features'])
            // .then(data => this.locations = data.length ? data : [this.value])
            .finally(() => this.searchRequested = false)
        }, REQUEST_TIMEOUT_TIMEOUT)
      }
    }
  }
})
