

















import Vue from 'vue'
import InformationBox from './InformationBox.vue'

const COOKIES_INFORMATION_BOX_CLOSURE_STATUS_LOCAL_STORAGE_KEY = 'cookiesInformationBoxClosed'

export default Vue.extend({
  components: {
    InformationBox
  },
  data () {
    return {
      cookiesInformationBoxClosed: true
    }
  },
  mounted () {
    this.cookiesInformationBoxClosed = localStorage?.getItem(COOKIES_INFORMATION_BOX_CLOSURE_STATUS_LOCAL_STORAGE_KEY) === 'true'
  },
  watch: {
    cookiesInformationBoxClosed () {
      if (this.cookiesInformationBoxClosed) {
        localStorage?.setItem(COOKIES_INFORMATION_BOX_CLOSURE_STATUS_LOCAL_STORAGE_KEY, 'true')
      }
    }
  }
})
