




















import Vue from 'vue'

export default Vue.extend({
  props: {
    place: {
      type: Object,
      required: true
    }
  },
})
