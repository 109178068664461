













import Vue from 'vue'
import TopPanel from './components/TopPanel.vue'
import CookiesInformationBox from './components/CookiesInformationBox.vue'
import LunchInformationBox from './components/LunchInformationBox.vue'
import Footer from './components/Footer.vue'

export default Vue.extend({
  components: {
    TopPanel,
    CookiesInformationBox,
    LunchInformationBox,
    Footer
  }
})
