import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'
import Home from './views/Home.vue'

export enum QueryParameters {
  MapBounds = 'map-bounds',
  EventSearchPhrase = 'event-search-phrase',
  TagSearchPhrase = 'tag-search-phrase',
  SelectedEventIdentifier = 'selected-event-identifier',
  SelectedTagIdentifier = 'selected-tag-identifier',
  SelectedPlaceIdentifier = 'selected-place-identifier'
}

export class Router extends VueRouter {
  setQueryParameters (queryParameters: Dictionary<string | (string | null)[] | null | undefined>, addHistoryEntry?: boolean) {
    const pushOrReplace = addHistoryEntry ? this.push : this.replace
    pushOrReplace.call(this, { query: { ...this.currentRoute.query, ...queryParameters } })
  }

  setQueryParameter (key: string, value: undefined | null | string | (null | string)[], addHistoryEntry?: boolean) {
    const pushOrReplace = addHistoryEntry ? this.push : this.replace
    pushOrReplace.call(this, { query: { ...this.currentRoute.query, [key]: value } })
    // this.setQueryParameters({ [key]: value }, addHistoryEntry)
  }

  removeQueryParameter (key: string, addHistoryEntry?: boolean) {
    this.setQueryParameter(key, undefined, addHistoryEntry)
  }
}

Vue.use(Router)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
    {
    path: '/:alias',
    name: 'Alias',
    component: Home
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // Route level code-splitting:
  //   // this generates a separate chunk ("about.[hash].js") for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

export default new Router({
  mode: 'history',
  base: '', // process.env.BASE_URL
  routes
})
