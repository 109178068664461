import { LatLngBounds } from 'leaflet'
import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import { Router, QueryParameters } from './router'

Vue.use(Vuex)
const POLAND_COORD = [[54.4732297, 29.9542236], [49.5501617, 12.3760986]]
const KRAKOW_COORD = [[50.1261338, 20.2173455], [49.9676668, 19.7922355]]
const DEFAULT_MAP_BOUNDS_ARRAY = POLAND_COORD
const FETCH_PLACES_REQUEST_URL_BASIS = `${location.origin}/en/watchout/api/places`
const NORTHWEST_MAP_CORNER_COORDINATES_PARAMETER_KEY = 'ne'
const SOUTHEAST_MAP_CORNER_COORDINATES_PARAMETER_KEY = 'sw'
const LIKED_PLACES_PARAMETER_KEY = 'lp'
const EVENT_IDENTIFIER_PARAMETER_KEY = 'event'
const TAG_IDENTIFIER_PARAMETER_KEY = 'tag'
const SEARCH_INPUT_BOXES_AUTOMATIC_HIDING_STATUS_LOCALE_STORAGE_KEY = 'searchInputBoxesShouldNotHideAutomatically'
const CURRENT_LANGUAGE_LOCALE_STORAGE_KEY = 'currentLanguage'
const LIKED_PLACES_STORAGE_KEY = 'likedPlaces'
const LAST_COORDINATES_LOCALE_STORAGE_KEY = 'lastCoordinates'

function fetchJSON (requestURL: URL) {
  return fetch(requestURL.toString()).then(response => {
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.status.toString())
    }
  })
}

export default (router: Router) => {
  // router = Vue.observable(router)

  return new Store({
    state: {
      router,
      selectedLocation: undefined as any,
      selectedEvent: undefined as any,
      selectedTag: undefined as any,
      fetchedPlaces: undefined as undefined | any[],
      searchInputBoxesHiddenWhenScreenIsSmallEnough: undefined as undefined | boolean,
      searchInputBoxesShouldNotHideAutomatically: localStorage?.getItem(SEARCH_INPUT_BOXES_AUTOMATIC_HIDING_STATUS_LOCALE_STORAGE_KEY)
        && (localStorage.getItem(SEARCH_INPUT_BOXES_AUTOMATIC_HIDING_STATUS_LOCALE_STORAGE_KEY) === 'true') as undefined | boolean,
      currentLanguage: localStorage?.getItem(CURRENT_LANGUAGE_LOCALE_STORAGE_KEY) || 'en' as string,
      isLunchMap: ['localhost', 'lunchmap.pl'].includes(window.location.hostname),
      likedPlaces: JSON.parse(localStorage?.getItem(LIKED_PLACES_STORAGE_KEY) || '[]') || [] as number[],
      currentLunchPlaceDisplay: undefined as any
    },
    getters: {
      mapBounds (state) : LatLngBounds {
        return new LatLngBounds((state.router.currentRoute.query[QueryParameters.MapBounds]
          && JSON.parse(state.router.currentRoute.query[QueryParameters.MapBounds] as string)) ||
          (localStorage?.getItem(LAST_COORDINATES_LOCALE_STORAGE_KEY) && JSON.parse(localStorage.getItem(LAST_COORDINATES_LOCALE_STORAGE_KEY) || '')) ||
           DEFAULT_MAP_BOUNDS_ARRAY)
      }
    },
    mutations: {
      setSelectedLocation (state, selectedLocation) {
        state.selectedLocation = selectedLocation
      },
      setSelectedEvent (state, { selectedEvent, addHistoryEntry }) {
        if (!selectedEvent) {
          state.router.currentRoute.query[QueryParameters.SelectedEventIdentifier] && state.router.removeQueryParameter(QueryParameters.SelectedEventIdentifier, addHistoryEntry)
        } else if (selectedEvent['id'] != (state.router.currentRoute.query[QueryParameters.SelectedEventIdentifier] as string)) {
          state.router.setQueryParameter(QueryParameters.SelectedEventIdentifier, selectedEvent['id'], addHistoryEntry)
        }
        state.selectedEvent = selectedEvent
      },
      setSelectedTag (state, { selectedTag, addHistoryEntry }) {
        if (!selectedTag) {
          state.router.currentRoute.query[QueryParameters.SelectedTagIdentifier] && state.router.removeQueryParameter(QueryParameters.SelectedTagIdentifier, addHistoryEntry)
        } else if (selectedTag['id'] != (state.router.currentRoute.query[QueryParameters.SelectedTagIdentifier] as string)) {
          state.router.setQueryParameter(QueryParameters.SelectedTagIdentifier, selectedTag['id'], addHistoryEntry)
        }
        state.selectedTag = selectedTag
      },
      setMapBounds (state, { mapBounds, addHistoryEntry }) {
        localStorage.setItem(LAST_COORDINATES_LOCALE_STORAGE_KEY, JSON.stringify([[mapBounds.getNorth(), mapBounds.getEast()], [mapBounds.getSouth(), mapBounds.getWest()]]))
        state.router.setQueryParameter(QueryParameters.MapBounds, JSON.stringify([[mapBounds.getNorth(), mapBounds.getEast()], [mapBounds.getSouth(), mapBounds.getWest()]]), false)
      },
      setFetchedPlaces (state, fetchedPlaces) {
        state.fetchedPlaces = fetchedPlaces
      },
      setCurrentLanguage (state, newCurrentLanguage) {
        state.currentLanguage = newCurrentLanguage
        localStorage?.setItem(CURRENT_LANGUAGE_LOCALE_STORAGE_KEY, newCurrentLanguage)
      },
      setLikedPlaces(state, likedPlaces) {
        state.likedPlaces = likedPlaces
        localStorage?.setItem(LIKED_PLACES_STORAGE_KEY, JSON.stringify(state.likedPlaces))
      },
      setSearchInputBoxesVisibilityStatusOnSmallEnoughScreens (state, searchInputBoxesHiddenWhenScreenIsSmallEnough: boolean) {
        state.searchInputBoxesHiddenWhenScreenIsSmallEnough = searchInputBoxesHiddenWhenScreenIsSmallEnough
      },
      setIfSearchInputBoxesShouldNotHideAutomatically (state, searchInputBoxesShouldNotHideAutomatically: boolean) {
        state.searchInputBoxesShouldNotHideAutomatically = searchInputBoxesShouldNotHideAutomatically
        localStorage?.setItem(SEARCH_INPUT_BOXES_AUTOMATIC_HIDING_STATUS_LOCALE_STORAGE_KEY, searchInputBoxesShouldNotHideAutomatically.toString())
      },
      setCurrentLunchPlaceDisplay (state, newCurrentLunchPlaceDisplay) {
        if(state.currentLunchPlaceDisplay && state.currentLunchPlaceDisplay.placelunchpost_set){
          const style = document.getElementById('current_lunch_place_display-' + state.currentLunchPlaceDisplay.id);
          if (style) {
            document.head.removeChild(style);
          }
        }

        state.currentLunchPlaceDisplay = newCurrentLunchPlaceDisplay;

        if(state.currentLunchPlaceDisplay && state.currentLunchPlaceDisplay.placelunchpost_set){
          const style = document.createElement('style');
          style.type = 'text/css';
          style.id = 'current_lunch_place_display-' + state.currentLunchPlaceDisplay.id

          const placelunchposts = state.currentLunchPlaceDisplay.placelunchpost_set;
          var css = '';
          for (let i in placelunchposts) {
            css += "\n" + placelunchposts[i].custom_styles;
          }
          if(css){
            style.appendChild(document.createTextNode(css));
            document.head.appendChild(style);
          }
        }
      }
    },
    actions: {
      toggleLikedPlace({state, commit}, placeId) {
        let likedPlaces = state.likedPlaces;
        if(likedPlaces.includes(placeId)) {
          likedPlaces = likedPlaces.filter((value: number, index: number, array: number[]) => value !== placeId);
        } else {
        likedPlaces.push(placeId)
        }
        const filteredLikedPlaces = likedPlaces.filter((value: number, index: number, array: number[]) => array.indexOf(value) === index);
        commit('setLikedPlaces', filteredLikedPlaces)
      },
      fetchPlaces ({ state, getters, commit }, triggeredByMapBoundsChange: boolean) {
        const placesFetchRequestURL = new URL(FETCH_PLACES_REQUEST_URL_BASIS)
        placesFetchRequestURL.searchParams.set(NORTHWEST_MAP_CORNER_COORDINATES_PARAMETER_KEY, getters.mapBounds.getNorth().toString())
        placesFetchRequestURL.searchParams.append(NORTHWEST_MAP_CORNER_COORDINATES_PARAMETER_KEY, getters.mapBounds.getWest().toString())
        placesFetchRequestURL.searchParams.set(SOUTHEAST_MAP_CORNER_COORDINATES_PARAMETER_KEY, getters.mapBounds.getSouth().toString())
        placesFetchRequestURL.searchParams.append(SOUTHEAST_MAP_CORNER_COORDINATES_PARAMETER_KEY, getters.mapBounds.getEast().toString())
        placesFetchRequestURL.searchParams.set(LIKED_PLACES_PARAMETER_KEY, state.likedPlaces)
        state.selectedEvent && placesFetchRequestURL.searchParams.set(EVENT_IDENTIFIER_PARAMETER_KEY, state.selectedEvent['id'])
        state.selectedTag && placesFetchRequestURL.searchParams.set(TAG_IDENTIFIER_PARAMETER_KEY, state.selectedTag['id'])
        return fetchJSON(placesFetchRequestURL).then(data => {
          if (triggeredByMapBoundsChange) {
            if (data['count']) {
              if (state.fetchedPlaces && state.fetchedPlaces.length) {
                const placesIdentifiers = new Set(state.fetchedPlaces.map((place: any) => place['id']))
                commit('setFetchedPlaces', state.fetchedPlaces.concat(data['results'].filter((place: any) => !placesIdentifiers.has(place['id']))))
              } else {
                commit('setFetchedPlaces', data['results'])
              }
            }
          } else {
            commit('setFetchedPlaces', data['results'])
          }
          return data['results']
        })
      },
      fetchPlace ({ state, commit }, placeIdentifier) {
        const placeFetchRequestURL = new URL(placeIdentifier, FETCH_PLACES_REQUEST_URL_BASIS + '/')
        state.selectedEvent && placeFetchRequestURL.searchParams.set(EVENT_IDENTIFIER_PARAMETER_KEY, state.selectedEvent['id'])
        state.selectedTag && placeFetchRequestURL.searchParams.set(TAG_IDENTIFIER_PARAMETER_KEY, state.selectedTag['id'])
        return fetchJSON(placeFetchRequestURL).then(place => {
          commit('setFetchedPlaces', (state.fetchedPlaces?.push(place) && state.fetchedPlaces) || [place])
          return place
        })
      }
    }
  })
}
