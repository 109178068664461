






import Vue from 'vue'

export default Vue.extend({
  props: {
    option: Object
  },
  data () {
    return {
    }
  }
})
