














import Vue from 'vue'
import Multiselect from 'vue-multiselect'

export default Vue.extend({
  components: {
    Multiselect
  },
  computed: {
    listElement () : HTMLElement {
      return (this.$refs.multiselect as Multiselect).$refs.list as HTMLElement
    }
  }
})
