






















import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import ComboBox from './ComboBox.vue'
import TagsComboBoxItem from './TagsComboBoxItem.vue'

const REQUEST_URL_BASIS = `${location.origin}/watchout/api/tags`
const SEARCH_PHRASE_PARAMETER_KEY = 'q'
const REQUEST_TIMEOUT_DURATION = 400

export default Vue.extend({
  props: {
    value: Object,
    searchPhrase: String,
    selectedTagIdentifier: Number
  },
  components: {
    ComboBox,
    TagsComboBoxItem
  },
  data () {
    return {
      tags: [] as any[],
      requestTimeoutIdentifier: undefined as undefined | number,
      multiselectComponentData: undefined as any,
      searchRequested: undefined as undefined | boolean
    }
  },
  computed: {
    selectedItemLabel () : string {
      return this.label(this.value)
    }
  },
  mounted () {
    this.multiselectComponentData = ((this.$refs.comboBox as InstanceType<typeof ComboBox>).$refs.multiselect as Multiselect).$data
    this.multiselectComponentData.search = this.searchPhrase || ''
    this.search()
  },
  watch: {
    selectedTagIdentifier () {
      this.$nextTick(() => {
        if (!this.searchRequested) {
          if (this.selectedTagIdentifier) {
            this.$emit('input', this.tags.find(tag => tag['id'] === this.selectedTagIdentifier) || this.tags[0] || null)
          } else {
            this.$emit('input', null)
          }
        }
      })
    },
    tags () {
      if (this.selectedTagIdentifier) {
        this.$emit('input', this.tags.find(tag => tag['id'] === this.selectedTagIdentifier) || this.tags[0] || null)
      } else if (this.searchPhrase) {
        this.$emit('input', this.tags[0] || null)
      } else {
        this.$emit('input', null)
      }
    },
    searchPhrase () {
      this.multiselectComponentData.search = this.searchPhrase || ''
      this.search()
    }
  },
  methods: {
    label (option: any) {
      return option && `${option['name']}`
    },
    search () {
      this.searchRequested = true
      clearTimeout(this.requestTimeoutIdentifier)
      this.requestTimeoutIdentifier = setTimeout(() => {
        const requestURL = new URL(REQUEST_URL_BASIS)
        this.searchPhrase && requestURL.searchParams.set(SEARCH_PHRASE_PARAMETER_KEY, this.searchPhrase)
        fetch(requestURL.toString()).then(response => response.json()).then(data => {
          if (data['count']) {
            this.tags = data['results']
          } else {
            this.tags = []
          }
        }).finally(() => this.searchRequested = false)
      }, REQUEST_TIMEOUT_DURATION)
    }
  }
})
