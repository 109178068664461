






















import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import ComboBox from './ComboBox.vue'
import EventsComboBoxItem from './EventsComboBoxItem.vue'

const REQUEST_URL_BASIS = `${location.origin}/en/watchout/api/events`
const SEARCH_PHRASE_PARAMETER_KEY = 'q'
const REQUEST_TIMEOUT_DURATION = 400

export default Vue.extend({
  props: {
    value: Object,
    searchPhrase: String,
    selectedEventIdentifier: Number
  },
  components: {
    ComboBox,
    EventsComboBoxItem
  },
  data () {
    return {
      events: [] as any[],
      requestTimeoutIdentifier: undefined as undefined | number,
      multiselectComponentData: undefined as any,
      searchRequested: undefined as undefined | boolean
    }
  },
  computed: {
    selectedItemLabel () : string {
      return this.label(this.value)
    }
  },
  mounted () {
    this.multiselectComponentData = ((this.$refs.comboBox as InstanceType<typeof ComboBox>).$refs.multiselect as Multiselect).$data
    this.multiselectComponentData.search = this.searchPhrase || ''
    this.search()
  },
  watch: {
    selectedEventIdentifier () {
      this.$nextTick(() => {
        if (!this.searchRequested) {
          if (this.selectedEventIdentifier) {
            this.$emit('input', this.events.find(event => event['id'] === this.selectedEventIdentifier) || this.events[0] || null)
          } else {
            this.$emit('input', this.events[0] || null)
          }
        }
      })
    },
    events () {
      if (this.selectedEventIdentifier) {
        this.$emit('input', this.events.find(event => event['id'] === this.selectedEventIdentifier) || this.events[0] || null)
      } else if (this.searchPhrase) {
        this.$emit('input', this.events[0] || null)
      } else {
        this.$emit('input', this.events[0] || null)
      }
    },
    searchPhrase () {
      this.multiselectComponentData.search = this.searchPhrase || ''
      this.search()
    }
  },
  methods: {
    label (option: any) {
      return option && `${option['id']}, ${option['name']}, ${option['from_date']}, ${option['to_date']}`
    },
    search () {
      if(this.$store.state.isLunchMap){
        this.events = [];
        return;
      }
      this.searchRequested = true
      clearTimeout(this.requestTimeoutIdentifier)
      this.requestTimeoutIdentifier = setTimeout(() => {
        const requestURL = new URL(REQUEST_URL_BASIS)
        this.searchPhrase && requestURL.searchParams.set(SEARCH_PHRASE_PARAMETER_KEY, this.searchPhrase)
        fetch(requestURL.toString()).then(response => response.json()).then(data => {
          if (data['count']) {
            this.events = data['results']
          } else {
            this.events = []
          }
        }).finally(() => this.searchRequested = false)
      }, REQUEST_TIMEOUT_DURATION)
    }
  }
})
