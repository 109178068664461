



































import store from '@/store';
import Vue from 'vue'

const TIME_TO_BE_DEFINED_EVENT_STATUS = 'Time to be defined'

export default Vue.extend({
  props: {
    option: Object
  },
  data () {
    return {
      timeToBeDefinedEventStatus: TIME_TO_BE_DEFINED_EVENT_STATUS
    }
  },
  computed: {
    leagueName() : string {
      if(!this.option['league']){
        return this.option['league_name'];
      }
      const language = this.$store.state.currentLanguage; 
      if (language){
        const nameKey = 'name_' + language;
        if(nameKey in this.option['league'] && this.option['league'][nameKey]){
          return this.option['league'][nameKey];
        }
      }
      return this.option['league']['name'];
    },
    homeTeamName() : string {
      const language = this.$store.state.currentLanguage; 
      if (language){
        const nameKey = 'name_' + language;
        if(nameKey in this.option['home_team'] && this.option['home_team'][nameKey]){
          return this.option['home_team'][nameKey];
        }
      }
      return this.option['home_team']['name'];
    },
    awayTeamName() : string {
      const language = this.$store.state.currentLanguage; 
      if (language){
        const nameKey = 'name_' + language;
        if(nameKey in this.option['away_team'] && this.option['away_team'][nameKey]){
          return this.option['away_team'][nameKey];
        }
      }
      return this.option['away_team']['name'];
    },
    eventName() : string {
      const language = this.$store.state.currentLanguage; 
      if (language){
        const nameKey = 'name_' + language;
        if(nameKey in this.option){
          return this.option[nameKey];
        }
      }
      return this.option['name'];
    },
  }
})
