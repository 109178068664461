
































































import Vue from 'vue'
import StreetsMap from '@/components/StreetsMap.vue'
import LocationsComboBox from '@/components/LocationsComboBox.vue'
import EventsComboBox from '@/components/EventsComboBox.vue'
import TagsComboBox from '@/components/TagsComboBox.vue'
import ComboBox from '@/components/ComboBox.vue'
import Icon from '@/components/Icon.vue'
import { DomEvent, LatLngBounds } from 'leaflet'
import { Router, QueryParameters } from '@/router'
import ToggleButton from '@/components/ToggleButton.vue'
import Toggle from '@/components/Toggle.vue'

const FETCH_PLACES_ON_MAP_BOUNDS_CHANGE_TIMEOUT_DURATION = 1000

export default Vue.extend({
  components: {
    StreetsMap,
    LocationsComboBox,
    EventsComboBox,
    TagsComboBox,
    Icon,
    ToggleButton,
    Toggle
  },
  data () {
    return {
      requestedMapBounds: this.$store.getters.mapBounds as null | LatLngBounds,
      locationSelected: undefined as undefined | boolean,
      fetchPlacesRequestTimeoutIdentifier: undefined as undefined | null | number,
      replaceHistoryEntryAfterEventSelection: undefined as undefined | boolean,
      replaceHistoryEntryAfterTagSelection: undefined as undefined | boolean,
      boundsChanged: undefined as undefined | boolean
    }
  },
  computed: {
    selectedLocation: {
      get () {
        return this.$store.state.selectedLocation
      },
      set (selectedLocation) {
        this.$store.commit('setSelectedLocation', selectedLocation)
      }
    },
    selectedEvent: {
      get () {
        return this.$store.state.selectedEvent
      },
      set (selectedEvent: any) {
        this.$store.commit('setSelectedEvent', { selectedEvent, addNewHistoryEntry: !this.replaceHistoryEntryAfterEventSelection })
        this.replaceHistoryEntryAfterEventSelection = false
      }
    },
    selectedTag: {
      get () {
        return this.$store.state.selectedTag
      },
      set (selectedTag: any) {
        this.$store.commit('setSelectedTag', { selectedTag, addNewHistoryEntry: !this.replaceHistoryEntryAfterTagSelection })
        this.replaceHistoryEntryAfterTagSelection = false
      }
    },
    places () : any[] {
      return this.$store.state.fetchedPlaces
    },
    eventSearchPhrase: {
      get () {
        return this.$route.query[QueryParameters.EventSearchPhrase]
      },
      set (searchPhrase: string) {
        (this.$router as Router).setQueryParameters({
          [QueryParameters.EventSearchPhrase]: searchPhrase || undefined,
          [QueryParameters.SelectedEventIdentifier]: undefined
        }, !!this.$route.query[QueryParameters.SelectedEventIdentifier] || !searchPhrase)
      }
    },
    tagSearchPhrase: {
      get () {
        return this.$route.query[QueryParameters.TagSearchPhrase]
      },
      set (searchPhrase: string) {
        (this.$router as Router).setQueryParameters({
          [QueryParameters.TagSearchPhrase]: searchPhrase || undefined,
          [QueryParameters.SelectedTagIdentifier]: undefined
        }, !!this.$route.query[QueryParameters.SelectedTagIdentifier] || !searchPhrase)
      }
    },
    selectedTagIdentifier () {
      return this.$route.query[QueryParameters.SelectedTagIdentifier] && parseInt(this.$route.query[QueryParameters.SelectedTagIdentifier] as string)
    },
    selectedEventIdentifier () {
      return this.$route.query[QueryParameters.SelectedEventIdentifier] && parseInt(this.$route.query[QueryParameters.SelectedEventIdentifier] as string)
    },
    selectedPlaceIdentifier () {
      return this.$route.query[QueryParameters.SelectedPlaceIdentifier] && parseInt(this.$route.query[QueryParameters.SelectedPlaceIdentifier] as string)
    },
    savedMapBounds: {
      get () : LatLngBounds {
        return this.$store.getters.mapBounds
      },
      set (mapBounds: LatLngBounds) {
        if (!this.locationSelected || !mapBounds.equals(this.savedMapBounds)) {
          this.$store.commit('setMapBounds', { mapBounds: mapBounds, addHistoryEntry: !this.requestedMapBounds })
        } else {
          this.locationSelected = false
        }
      }
    },
    locationBoundingBoxAsBounds () : LatLngBounds {
      if (this.selectedLocation['properties']['extent']) {
        return this.selectedLocation && new LatLngBounds([
          this.selectedLocation['properties']['extent'][1],
          this.selectedLocation['properties']['extent'][0]
        ], [
          this.selectedLocation['properties']['extent'][3],
          this.selectedLocation['properties']['extent'][2]
        ])
      } else {
        return this.selectedLocation && new LatLngBounds([
          this.selectedLocation['geometry']['coordinates'][1],
          this.selectedLocation['geometry']['coordinates'][0]
        ], [
          this.selectedLocation['geometry']['coordinates'][1],
          this.selectedLocation['geometry']['coordinates'][0]
        ])
      }
      // return this.selectedLocation && new LatLngBounds([this.selectedLocation['boundingbox'][0], this.selectedLocation['boundingbox'][2]], [this.selectedLocation['boundingbox'][1], this.selectedLocation['boundingbox'][3]])
    },
    searchInputBoxesHiddenWhenScreenIsSmallEnough: {
      get () {
        return this.$store.state.searchInputBoxesHiddenWhenScreenIsSmallEnough
      },
      set (searchInputBoxesHiddenWhenScreenIsSmallEnough) {
        this.$store.commit('setSearchInputBoxesVisibilityStatusOnSmallEnoughScreens', searchInputBoxesHiddenWhenScreenIsSmallEnough)
      }
    },
    searchInputBoxesShouldHideAutomatically: {
      get () {
        return !this.$store.state.searchInputBoxesShouldNotHideAutomatically
      },
      set (searchInputBoxesShouldHideAutomatically) {
        this.$store.commit('setIfSearchInputBoxesShouldNotHideAutomatically', !searchInputBoxesShouldHideAutomatically)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const locationsComboBox = this.$refs.locationsComboBox as InstanceType<typeof LocationsComboBox>
      const eventsComboBox = this.$refs.eventsComboBox as InstanceType<typeof EventsComboBox>
      const tagsComboBox = this.$refs.tagsComboBox as InstanceType<typeof TagsComboBox>
      [
        locationsComboBox.$el as HTMLElement,
        (locationsComboBox.$refs.comboBox as InstanceType<typeof ComboBox>).listElement,
        eventsComboBox.$el as HTMLElement,
        (eventsComboBox.$refs.comboBox as InstanceType<typeof ComboBox>).listElement,
        tagsComboBox.$el as HTMLElement,
        (tagsComboBox.$refs.comboBox as InstanceType<typeof ComboBox>).listElement,
        this.$slots.default![0].componentInstance!.$refs.title as HTMLElement
      ].forEach(element => {
        DomEvent.disableClickPropagation(element)
        DomEvent.disableScrollPropagation(element)
      })
    })
  },
  watch: {
    selectedLocation () {
      this.locationSelected = true
      this.requestedMapBounds = this.locationBoundingBoxAsBounds
    },
    places () {
      if (this.selectedPlaceIdentifier && !this.places?.find(place => place['id'] === this.selectedPlaceIdentifier)) {
        this.$store.dispatch('fetchPlace', this.selectedPlaceIdentifier)
          .catch(() => (this.$router as Router).removeQueryParameter(QueryParameters.SelectedPlaceIdentifier))
      }
    },
    eventSearchPhrase (currentEventSearchPhrase, previousEventSearchPhrase) {
      previousEventSearchPhrase && currentEventSearchPhrase && (this.replaceHistoryEntryAfterEventSelection = true)
    },
    TagSearchPhrase (currentTagSearchPhrase, previousTagSearchPhrase) {
      previousTagSearchPhrase && currentTagSearchPhrase && (this.replaceHistoryEntryAfterTagSelection = true)
    },
    selectedEvent (currentSelectedEvent, previousSelectedEvent) {
      if (!currentSelectedEvent || !previousSelectedEvent || currentSelectedEvent['id'] !== previousSelectedEvent['id']) {
        this.fetchPlaces()
      }
    },
    selectedTag (currentSelectedTag, previousSelectedTag) {
      if (!currentSelectedTag || !previousSelectedTag || currentSelectedTag['id'] !== previousSelectedTag['id']) {
        this.fetchPlaces()
      }
    },
    savedMapBounds (currentSavedMapBounds, previouslySavedMapBounds) {
      if (!currentSavedMapBounds.equals(previouslySavedMapBounds)) {
        if (this.boundsChanged) {
          this.fetchPlacesDueToMapBoundsChange()
          this.boundsChanged = false
        } else if (this.requestedMapBounds) {
          this.fetchPlacesDueToMapBoundsChange()
          this.requestedMapBounds = null
        } else {
          this.requestedMapBounds = this.savedMapBounds
        }
      }
    }
  },
  methods: {
    fetchPlacesDueToMapBoundsChange () {
      if (this.$store.state.fetchedPlaces !== undefined
        || this.$store.state.selectedEvent
        || this.$store.state.selectedTag
        || (!this.$route.query[QueryParameters.TagSearchPhrase] && !this.$route.query[QueryParameters.SelectedTagIdentifier])
        || (!this.$route.query[QueryParameters.EventSearchPhrase] && !this.$route.query[QueryParameters.SelectedEventIdentifier])) {
        if (this.locationSelected) {
          this.locationSelected = false
          this.fetchPlaces(true)
        } else {
          if (!this.fetchPlacesRequestTimeoutIdentifier) {
            this.fetchPlaces(true)
            this.fetchPlacesRequestTimeoutIdentifier = setTimeout(() => {
              this.fetchPlacesRequestTimeoutIdentifier = null
            }, FETCH_PLACES_ON_MAP_BOUNDS_CHANGE_TIMEOUT_DURATION)
          } else {
            clearTimeout(this.fetchPlacesRequestTimeoutIdentifier)
            this.fetchPlacesRequestTimeoutIdentifier = setTimeout(() => {
              this.fetchPlaces(true)
              this.fetchPlacesRequestTimeoutIdentifier = null
            }, FETCH_PLACES_ON_MAP_BOUNDS_CHANGE_TIMEOUT_DURATION)
          }
        }
      }
    },
    fetchPlaces (triggeredByMapBoundsChange?: boolean) {
      this.$store.dispatch('fetchPlaces', triggeredByMapBoundsChange)
    },
    hideSearchInputBoxesIfRequired () {
      this.searchInputBoxesShouldHideAutomatically && this.selectedEvent && (this.searchInputBoxesHiddenWhenScreenIsSmallEnough = true)
    }
  }
})
