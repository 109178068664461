import { render, staticRenderFns } from "./PlaceInformationBox.vue?vue&type=template&id=6c96dec6&scoped=true&"
import script from "./PlaceInformationBox.vue?vue&type=script&lang=ts&"
export * from "./PlaceInformationBox.vue?vue&type=script&lang=ts&"
import style0 from "./PlaceInformationBox.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./PlaceInformationBox.vue?vue&type=style&index=1&id=6c96dec6&lang=scss&scoped=true&"
import style2 from "./PlaceInformationBox.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c96dec6",
  null
  
)

export default component.exports