














import Vue from 'vue'
import Icon from './Icon.vue'

export default Vue.extend({
  components: {
    Icon
  },
  props: {
    value: Boolean,
    initialIconName: String,
    initialIconIsSolid: Boolean,
    initialIconSize: Number,
    iconWhenToggledName: String,
    iconWhenToggledIsSolid: Boolean,
    iconWhenToggledSize: Number
  }
})
