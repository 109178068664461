














































import Vue from 'vue'
import InformationBox from './InformationBox.vue'


export default Vue.extend({
  components: {
    InformationBox
  },
  updated(){
    if ((window as any).FB) {
      (window as any).FB.XFBML.parse();
    }
  },
  methods: {
    postDisplay(placelunchpost: any) {
      if(placelunchpost.iframe_url){
        return 'iframe';
      }
      if(placelunchpost.facebook_permalink_url && !this.checkIsMobile()){
        return 'facebook_permalink';
      }
      if(this.checkIsMobile() && placelunchpost.facebook_permalink_url){
        return 'description';
      }
      return 'description';
    },
    checkIsMobile() {
      return window.innerWidth <= 768;
    },
    formatDate(date: any) {
      const locale = this.$store.state.currentLanguage == 'pol' ? 'pl' : 'en-US';
      return date.toLocaleDateString(locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
})
