export const messages = {
    en: {
      run_bar: 'You run a bar? Contact us:',
      run_bar_lunch: 'You serve lunches at you restaurant? Contact us:',
      place_owner: 'Place owner:',
      privacy_policy: 'Privacy policy',
      links: 'Links',
      warning: 'Hello',
      warning_message: 'Welcome to PubWatch! Find pubs and restaurants near you that transmit sport events.',//'This application is still under development. Please forgive us any possible issues or missing features.',
      warning_message_lunch: 'Welcome to LunchMap! Find restaurants serving lunches near you',
      cookies: 'Information about Internet cookies',
      cookies_message: 'We would like to inform you that in order to optimize the content available on our website and to adapt it to your individual needs, we use information saved using cookies on end users\' devices. Cookies can be controlled by the user through the settings of his web browser. By continuing to use our website without changing the browser settings, the user accepts the use of cookies. Read more on our',
      place_count_message: 'Information about events or transmissions in {numberOfPlaces} places already available!',
      place_count_lunch_message: 'Information about lunches in {numberOfPlaces} places already available!',
      i_understand: 'I understand',
      automatic_hiding: 'Automatic hiding',
      transmissions: 'Transmissions events',
      copy_link_message: 'Copy a link to this place and event.',
      copy_link_save_success: 'The link saved to the clipboard!',
      copy_link_save_failed: 'Failed to save the link to the clipboard.',
      day_schedule: 'Day schedule',
      all_schedule: 'All time schedule',
      ai_predicted: 'AI PREDICTED, contact bar for reassurance',
      or_fill: 'or fill in the',
      offer: 'Offer',
      form: 'Form',
      search_event: 'Search for an event...',
      search_tag: 'Extra filter...',
      search_location: 'Search for a location...',
      contains_license: 'Place contains license to transmit league',
      lunch_info: 'Lunch menu at {name}',
      show_menu: 'Show lunch menu',
      like_place_save_success: 'Like place!',
      like_place_save_failed: 'Unlike place!',
      like_place_message: 'Like place',
      original_post: 'Link to original post',
      lunch_warning: 'Dear client, these menus may not always be up to date. Please be advised. We welcome you to check the restaurants website',
      no_posts_info: 'No lunch menu downloaded at the moment. We invite you to check the restaurants website for the current lunch menu',
      missing_menu: 'Missing current menu. Check out the places website!',
      place_link: 'Place website'
    },
    pl: {
      contains_license: 'Lokal posiada licencje do transmitowania tej ligii',
      search_location: 'Szukaj lokalizacji...',
      search_event: 'Szukaj wydarzenia...',
      search_tag: 'Dodatkowy filtr...',
      form: 'Formularz',
      or_fill: 'albo wypełnij',
      offer: 'Oferta',
      run_bar: 'Posiadasz lokal? Napisz do nas:',
      run_bar_lunch: 'Serwujecie lunche w swojej restauracji? Napiszcie do nas:',
      place_owner: 'Właściciel lokalu:',
      privacy_policy: 'Polityka prywatności',
      links: 'Linki',
      warning: 'Witamy',
      warning_message: 'Witamy w PubWatch! Znajdź puby i restauracje wyświetlajce transmisje sportowe w Twojej okolicy.',//'Ta aplikacja jest w trakcie rozwoju. Prosimy wybaczyć za wszelkie problemy i brakujące funkcjonalności',
      warning_message_lunch: 'Witamy w LunchMap! Znajdź restauracje serwujące lunche niedaleko Ciebie',
      cookies: 'Informacje o ciasteczkach',
      cookies_message: 'Serwis PubWatch wykorzystuje pliki cookies wyłącznie w celu prawidłowego działania wszystkich funkcji serwisu, zapewnienia bezpieczeństwa w związku z korzystaniem z serwisu oraz w celach analitycznych. Jeżeli chcesz dowiedzieć się więcej przeczytaj naszą ',
      place_count_message: 'Informacje o wydarzeniach sprotowych i transmisjach w {numberOfPlaces} lokalach na stronie!',
      place_count_lunch_message: 'Informacje o lunchach w {numberOfPlaces} lokalach na stronie!',
      i_understand: 'Rozumiem',
      automatic_hiding: 'Chowaj automatycznie',
      transmissions: 'Transmituje wydarzenia',
      copy_link_message: 'Kopiuj link do miejsca i wydarzenia.',
      copy_link_save_success: 'Link został zapisany do schowka!',
      copy_link_save_failed: 'Nie udało się zapisać linku do schowka.',
      day_schedule: 'Dziejszy terminarz',
      all_schedule: 'Cały terminarz',
      ai_predicted: 'Przewidzenia AI, skontaktuj sie z bar\'em dla upewnienia',
      lunch_info: 'Lunch menu u {name}',
      show_menu: 'Pokaż lunch menu',
      like_place_save_success: 'Polub miejsce!',
      like_place_save_failed: 'Cofnij polubienie miejsca',
      like_place_message: 'Polub miejsce',
      original_post: 'Link do orginalnego post',
      lunch_warning: 'Drodzy goście, lunch menu nie koniecznie zawsze jest na bieżąco. Proszę to mieć w pamięci. Zachęcamy do sprawdzenia na stronie restauracji',
      no_posts_info: 'Brak pobranego lunch menu na chwile obecna. Zapraszamy do sprawdzenia lunch menu na stronie restauracji',
      missing_menu: 'Brak aktualnego menu. Sprawdź na stronie lokalu!',
      place_link: 'Strona lokalu'
    }
  }
